import React from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    navigate("/")
  }

  return null
}

export default NotFoundPage
